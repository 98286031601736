<div class="job-detail-modal">

    <div class="modal-body container-fluid">
        <div class="scroll-sec">
            <div class="items-sec">
                <div class="item" *ngFor="let item of jobDetailsItemList">
                    <div class="title-line d-flex justify-content-between">
                        <h6 class="title">
                            {{getJobItemLabel((categoryType === 16 &&
                            item?.mainCategory?.title) ?
                            item?.mainCategory?.title + ' - ' :
                            '')}}
                            {{getJobItemLabel(item?.category?.displayText ?? item?.displayText)}}
                            {{getJobItemLabel((categoryType === 16 && item?.selectedOption) ?
                            ' (' + item?.selectedOption + ')' : '')}}
                        </h6>
                        <h6 class="price">{{getTranslatedAmount(getPriceText(item.total ??
                            0))}}{{item?.monthlyPackage?getTranslatedText('/time'):''}}</h6>
                    </div>
                    <div *ngIf="item?.addOnList?.length > 0"
                        class="title-line addon-line d-flex justify-content-between mb-0">
                        <h6
                            class="addon-txt">{{getTranslatedText("Add Ons")}}:<b
                                *ngFor="let addon of item.addOnList; index as i">
                                <span *ngIf="addon?.unitCount">
                                    {{addon?.unitCount}} -
                                </span>{{addon.itemName +
                                getAddOnCountText(addon)}}<b
                                    *ngIf="item.addOnList?.length > 1 && (i+1 != item.addOnList?.length)">,</b></b>
                        </h6>
                        <h6 *ngIf="item.addOnSum > 0"
                            class="price">{{getPriceText(item.addOnSum ??
                            0)}}{{item?.monthlyPackage?getTranslatedText('/time'):''}}</h6>
                    </div>
                    <div *ngIf="item?.monthlyPackage"
                        class="title-line addon-line d-flex justify-content-between">
                        <h6 class="addon-txt"> {{item?.monthlyPackage}}
                        </h6>
                    </div>
                    <div
                        *ngIf="item?.mainCategory?.isPackage && item?.packageAddonDisplayList?.length > 0"
                        class="title-line addon-line">
                        <h6 class="addon-txt"
                            *ngFor="let e of item?.packageAddonDisplayList">
                            {{e?.key}}: <b>{{e?.value}}</b>
                        </h6>
                    </div>
                    <div
                        *ngIf="categoryType === 16 && item?.categoryType === 1 && item?.level0SubType?.itemName"
                        class="title-line addon-line">
                        <h6 class="addon-txt">Service Type:
                            <b>{{item?.level0SubType?.itemName}}</b></h6>
                    </div>
                    <div
                        *ngIf="categoryType === 16 && !item?.mainCategory?.isPackage && item?.unitCount > 0 && item?.subType?.itemSubName"
                        class="title-line addon-line">
                        <h6 class="addon-txt">{{item?.subType?.itemSubName}}:
                            <b>{{item?.unitCount}}
                                {{item?.subType?.countText}}</b></h6>
                    </div>
                    <!-- <div *ngIf="item?.selectedOptionsList?.length > 0"
                        class="title-line addon-line d-flex justify-content-between">
                        <h6 class="addon-txt">Option:<b *ngFor="let option of item.selectedOptionsList; index as i">
                                {{option.itemName}}<b
                                    *ngIf="item.selectedOptionsList?.length > 1 && (i+1 != item.selectedOptionsList?.length)">,</b></b>
                        </h6>
                    </div> -->
                    <div *ngIf="showEditBtn || showRemoveBtn"
                        class="btn-line d-flex justify-content-between">
                        <p *ngIf="showEditBtn" class="edit-btn"
                            (click)="editItem(item, item.mainCategory)">{{getTranslatedText("Edit")}}</p>
                        <p *ngIf="showRemoveBtn" class="remove-btn"
                            (click)="removeItem(item.uid, item)">{{getTranslatedText("Remove")}}</p>
                    </div>
                </div>
            </div>

            <div class="other-info-sec" *ngIf="otherInfoList.length > 0">
                <div class="item" *ngFor="let item of otherInfoList">
                    <h6>{{getTranslatedText(item.title)}}</h6>
                    <div class="value d-flex justify-content-between">
                        <div class="left-sec"
                            *ngIf="item?.title !== 'DATE & TIME'">
                            <p *ngFor="let val of item.valueList">{{val}}</p>
                        </div>
                        <div class="left-sec"
                            *ngIf="item?.title === 'DATE & TIME'">
                            <p><b *ngIf="adminEditDate">{{adminEditDate}}</b>
                                {{adminEditDate ? '' : item.date}} -
                                {{this.deliverySlot?.slot ?? item.time}}
                                <b *ngIf="adminEditDate"> -
                                    {{getTranslatedText("Edited")}}</b>
                            </p>
                        </div>
                        <a *ngIf="item?.editIndex !== null" class="edit-btn"
                            (click)="editOtherInfo(item?.editIndex)">{{getTranslatedText("Edit")}}</a>
                    </div>
                </div>
            </div>

            <div class="pricing-sec">
                <div class="price-line d-flex justify-content-between"
                    *ngFor="let e of jobDetailPricingList">
                    <h6 class="title"
                        [ngClass]="{'discount-included':setActiveClass(e.key)}">{{getTranslatedText(e.title)}}</h6>
                    <h6 class="price"
                        [ngClass]="{'discount-included':setActiveClass(e.key)}">{{getTranslatedAmount((e.value))}}</h6>
                </div>
                <div *ngIf="hasEditedPrice" class="price-line">
                    <h6
                        class="edit-price-txt">*{{getTranslatedText("Price Edited")}}</h6>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer d-flex align-items-center justify-content-between">
        <div class="left-sec">
            <h4
                [ngClass]="{'discount-included':isDiscountIncluded}">{{getTranslatedAmount(getPriceText(totalAmountVal))}}
                <p
                    *ngIf="hasEditedPrice">{{getTranslatedText("Price Edited")}}</p>
            </h4>
            <a (click)="close()">{{getTranslatedText("Hide Order Summary")}}</a>
        </div>
        <button (click)="proceed()">{{getTranslatedText(btnText)}}</button>
    </div>
</div>
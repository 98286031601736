<section class="yc-task">
    <div class="container">
        <a routerLink="/my-task"
            class="back-button"><span></span>{{getTranslatedText('Task Details')}}</a>
        <div class="yc-task__wrap">
            <div class="yc-task__item-grid">
                <!-- <div class="task-control">
                    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                        <img src="assets/images/icons/task-back.png" alt="">
                    </button>
                    <span>View Task Details</span>
                </div> -->
                <div class="task-details">
                    <!-- if status is active or confirmed, add `task-details__status--active` class -->
                    <div class="task-details__status" [ngClass]="{
                      'task-details__status--active' :  setStatusColor(task) === 'GREEN',
                      'task-details__status--chat' :  setStatusColor(task) === 'THEME',
                       'task-details__status--pending' :  setStatusColor(task) === 'RED'
                      }">{{getTranslatedText(setStatus())}}</div>
                    <div
                        class="task-details__title">{{getTranslatedText(task?.subCategory?.subCategoryName)}}</div>
                    <div class="task-details__id">{{getTranslatedText('TASK ID:')}}
                        {{task?.docId}}</div><!--task?.bookingId ??-->
                    <div class="task-details__body">
                        <div>
                            <span>{{getTranslatedText('Description')}}</span>
                            {{getTranslatedText(task?.description ?
                            task?.description : 'No description provided')}}
                        </div>
                        <div class="d-flex flex-row">
                            <div class="flex-fill">
                                <span>{{getTranslatedText('Service')}}</span>
                                {{getTranslatedText(task?.subCategory?.subCategoryName)}}
                            </div>
                            <div class="flex-fill">
                                <span>{{getTranslatedText('Location')}}</span>
                                {{getTranslatedText(setAddress())}}
                            </div>
                        </div>
                        <div>
                            <span>{{getTranslatedText('Budget')}}</span>
                            {{getTranslatedAmount(getBudgetAmount())}}
                        </div>
                        <div class="d-flex flex-row">
                            <div class="flex-fill">
                                <span>{{getTranslatedText('Date')}}</span>
                                {{getTranslatedText(getPreferredDate())}}
                            </div>
                            <div class="flex-fill">
                                <span>{{getTranslatedText('Time')}}</span>
                                {{getTranslatedText(getPreferredTime())}}
                            </div>
                        </div>
                        <!-- <div>
                    <span>Sent to</span>
                    24 Vendors
                </div> -->
                        <div *ngIf="setImageList().length > 0">
                            <span>{{getTranslatedText('Attachements')}}</span>
                            <div class="d-flex flex-row flex-wrap">
                                <figure *ngFor="let image of setImageList();">
                                    <img [src]="image" alt="attachment-name">
                                </figure>
                                <!-- <figure>
                            <img src="assets/images/task-img.png" alt="attachment-name">
                        </figure>
                        <figure>
                            <img src="assets/images/task-img.png" alt="attachment-name">
                        </figure> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="yc-task__item-detail">
                <app-call-center-card></app-call-center-card>
            </div>
        </div>
    </div>
</section>

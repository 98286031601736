import { Component, Input, OnInit } from '@angular/core';
import { PriceBreakdown } from 'src/app/models/priceBreakdown.model';
import { DomainService } from 'src/app/services/domain.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';

@Component({
  selector: 'app-price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss']
})
export class PriceBreakdownComponent implements OnInit {
  priceBreakdownList: PriceBreakdown[] = [];

  @Input()
  public priceBreakdownData!: PriceBreakdown[];

  constructor(private quickbookingServcice: QuickBookingService, private domainService: DomainService) { }

  ngOnInit(): void {
    this.priceBreakdownList = this.priceBreakdownData;
  }

  closeModal() {
    this.quickbookingServcice.closeModal();
  }

  public getTranslatedText = (text: string | any) => this.domainService.getTranslatedText(text);
  public getTranslatedValue(value: string) {
    if (value?.startsWith('AED')) {
      return this.domainService.getTranslatedAmount(value);

    } else {
      return this.getTranslatedText(value);
    }
  }

}

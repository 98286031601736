import { Component, OnInit } from '@angular/core';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-call-center-card',
  templateUrl: './call-center-card.component.html',
  styleUrls: ['./call-center-card.component.scss']
})
export class CallCenterCardComponent implements OnInit {

  constructor(private domainService:DomainService) { }

  ngOnInit(): void {
  }

  public getTranslatedText = (text: string | any) => this.domainService.getTranslatedText(text);


}
